<template>
  <div class="hello">
      <div id='container'>
        <div id='DmanTip'  class="position-item" >
          <div id='date'>
            Fecha: 13.03.2021 14:28:43 UTC
          </div>
          <div id='tip class="position-item" '>
            If you're holding xvs or bake or some other thing you are past targets or stuck into, sell it for this - since they are no longer gems, rather sell them (if you miss money), if you lack money and enter into this. This is a good opportunity, very well analyzed by us. Cheers!
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BAKE',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
